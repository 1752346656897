import JiraBoardSection from "../components/jiraBoard";

const  JiraBoard = () => {
  return (
    <section className="flex container">
      <JiraBoardSection />
    </section>
  );
};

export default JiraBoard;
